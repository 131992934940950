import { useRef } from 'react'
import { FireworksHandlers,Fireworks } from '@fireworks-js/react'

export default function FireworkEffect() {
  const ref = useRef()

  const toggle = () => {
    if (!ref.current) return
    if (ref.current.isRunning) {
      ref.current.stop()
    } else {
      ref.current.start()
    }
  }

  return (
    <>
      {/* <div
        style={{ display: 'flex', gap: '4px', position: 'absolute', zIndex: 1 }}
      > */}
        {/* <button onClick={() => toggle()}>Toggle</button>
        <button onClick={() => ref.current?.clear()}>Clear</button> */}
      
      <Fireworks
      ref={ref}
      options={{ opacity: 0.5 ,
        particleAmount: 10, 
        speed: 2,
        acceleration: 1,
      }}
      
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 0,
      }}
    />
    </>
  )
}