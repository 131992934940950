// Stage.js
import Ticker from './Ticker.js';


const Stage = (function StageFactory(window, document, Ticker) {
    'use strict';

    function invariant(condition, msg) {
        if (!condition) {
            throw new Error('Stage Warning: ' + msg);
        }
    }

    class Sprite {
        constructor(config) {
            const { x = 0, y = 0, width, height, rotation = 0, scaleX = 1, scaleY = 1, transformOriginX = 0.5, transformOriginY = 0.5, draw } = config;
            this.x = x;
            this.y = y;
            this.width = width;
            this.height = height;
            this.rotation = rotation;
            this.scaleX = scaleX;
            this.scaleY = scaleY;
            this.transformOriginX = transformOriginX;
            this.transformOriginY = transformOriginY;
            this.userDraw = draw;
        }

        setTransformOrigin(x, y) {
            this.transformOriginX = x;
            this.transformOriginY = y;
        }

        drawOnContext(ctx) {
            const { x, y, width, height, rotation, scaleX, scaleY, transformOriginX, transformOriginY } = this;
            const shouldRotate = rotation !== 0;
            const shouldScale = scaleX !== 1 || scaleY !== 1;
            const shouldTransform = shouldRotate || shouldScale;
            const offsetX = width * transformOriginX;
            const offsetY = height * transformOriginY;
            ctx.save();
            !shouldTransform && ctx.translate(x, y);
            shouldTransform && ctx.translate(x + offsetX, y + offsetY);
            shouldRotate && ctx.rotate(rotation);
            shouldScale && ctx.scale(scaleX, scaleY);
            shouldTransform && ctx.translate(-offsetX, -offsetY);
            this.userDraw(ctx, this);
            ctx.restore();
        }
    }

    class CachedSprite {
        constructor(stage, config) {
            const { x = 0, y = 0, width, height, rotation = 0, scaleX = 1, scaleY = 1, transformOriginX = 0.5, transformOriginY = 0.5, draw, performFirstDraw = true, autoClear = true } = config;
            this.stage = stage;
            this.x = x;
            this.y = y;
            this.rotation = rotation;
            this.scaleX = scaleX;
            this.scaleY = scaleY;
            this.transformOriginX = transformOriginX;
            this.transformOriginY = transformOriginY;
            this.canvas = document.createElement('canvas');
            this.ctx = this.canvas.getContext('2d');
            this.userDraw = draw;
            this.autoClear = autoClear;
            this.resize(width, height);
            performFirstDraw && this.redraw();
        }

        resize(w, h) {
            this.width = w;
            this.height = h;
            this.naturalWidth = w * this.stage.dpr;
            this.naturalHeight = h * this.stage.dpr;
            this.canvas.width = this.naturalWidth;
            this.canvas.height = this.naturalHeight;
        }

        setTransformOrigin(x, y) {
            this.transformOriginX = x;
            this.transformOriginY = y;
        }

        redraw() {
            const { stage, ctx } = this;
            this.autoClear && ctx.clearRect(0, 0, this.naturalWidth, this.naturalHeight);
            stage.autoScale && ctx.scale(stage.dpr, stage.dpr);
            this.userDraw(ctx, this);
            ctx.setTransform(1, 0, 0, 1, 0, 0);
        }

        drawOnContext(ctx) {
            const { canvas, x, y, width, height, rotation, scaleX, scaleY, transformOriginX, transformOriginY } = this;
            const shouldRotate = rotation !== 0;
            const shouldScale = scaleX !== 1 || scaleY !== 1;
            if (!shouldRotate && !shouldScale) {
                ctx.drawImage(canvas, x, y, width, height);
            } else {
                const offsetX = width * transformOriginX;
                const offsetY = height * transformOriginY;
                ctx.save();
                ctx.translate(x + offsetX, y + offsetY);
                shouldRotate && ctx.rotate(rotation);
                shouldScale && ctx.scale(scaleX, scaleY);
                ctx.drawImage(canvas, -offsetX, -offsetY, width, height);
                ctx.restore();
            }
        }
    }

    class Stage {
        constructor({ container, className, width, height, fullscreen = true, highDPI = true, autoScale = true }) {
            let containerElement;
            if (container instanceof HTMLElement) {
                containerElement = container;
            } else if (typeof container === 'string') {
                containerElement = document.querySelector(container);
                invariant(containerElement, `container CSS selector "${container}" did not match any elements.`);
            } else {
                invariant(false, 'Invalid container type.');
            }

            const canvas = document.createElement('canvas');
            containerElement.appendChild(canvas);

            if (className) {
                invariant(typeof className === 'string', 'className must be a string.');
                canvas.classList.add(className);
            }

            invariant(!width || typeof width === 'number', 'width must be numeric.');
            invariant(!height || typeof height === 'number', 'height must be numeric.');

            this.canvas = canvas;
            this.ctx = canvas.getContext('2d');
            this.canvas.style.touchAction = 'none';
            this.speed = 1;
            this.dpr = highDPI ? ((window.devicePixelRatio || 1) / (this.ctx.backingStorePixelRatio || 1)) : 1;
            this.autoScale = autoScale && highDPI;
            this.onTick = null;
            this.onDraw = null;
            this.onResize = null;
            this.resize(fullscreen ? window.innerWidth : width, fullscreen ? window.innerHeight : height);
            this.createSprite = config => new Sprite(config);
            this.createCachedSprite = config => new CachedSprite(this, config);
            if (fullscreen) {
                this.canvas.style.display = 'block';
                window.addEventListener('resize', () => {
                    this.resize(window.innerWidth, window.innerHeight);
                });
            }
            Stage.stages.push(this);
        }

        dispatchEventWithSelf(eventName) {
            const listener = this[eventName];
            listener && listener(this);
        }

        resize(w, h) {
            this.width = w;
            this.height = h;
            this.naturalWidth = w * this.dpr;
            this.naturalHeight = h * this.dpr;
            this.canvas.width = this.naturalWidth;
            this.canvas.height = this.naturalHeight;
            this.canvas.style.width = w + 'px';
            this.canvas.style.height = h + 'px';
            this.dispatchEventWithSelf('onResize');
        }
    }

    Stage.stages = [];

    Stage.windowToCanvas = function windowToCanvas(canvas, x, y) {
        const bbox = canvas.getBoundingClientRect();
        return {
            x: (x - bbox.left) * (canvas.width / bbox.width),
            y: (y - bbox.top) * (canvas.height / bbox.height)
        };
    };

    Ticker.addListener(function handleTick(frameTime, lag) {
        Stage.stages.forEach(stage => {
            stage.simTime = stage.speed * frameTime;
            stage.simSpeed = stage.speed * lag;
            stage.lag = lag;
            stage.dispatchEventWithSelf('onTick');
            stage.autoScale && stage.ctx.scale(stage.dpr, stage.dpr);
            stage.dispatchEventWithSelf('onDraw');
            stage.autoScale && stage.ctx.setTransform(1, 0, 0, 1, 0, 0);
        });
    });

    return Stage;
})(window, document, Ticker);

export default Stage;
