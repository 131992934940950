import React from 'react';
import { Typography } from '@mui/material';
import { keyframes } from '@mui/system';

const StyledTypography = () => {
  const popUp = keyframes`
  text-shadow: 0 3px #1B2845, 0 5px #1B2845, 0 7px #1B2845;
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

  return (
    <Typography
      variant="h4"
      sx={{
        position: 'relative',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'center',
        width: '50%',
        height: '50%',
        margin: 0,
        // color:'#d2407c',
        fontWeight: '900!important',
        fontSize: '4vw!important',
        textTransform: 'uppercase',
        fontFamily: 'Georgia',
        letterSpacing: '0.05em', // Adjust letter spacing
        background: 'linear-gradient(to left, #ff1b00, #e4f712fa)', // White to burgundy gradient
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent', // Ensures text color is transparent to show gradient
        
        animation: `${popUp} 0.8s ease-out 0s infinite` ,
        width: { xs: '80%', sm: '60%', md: '50%' },
        height: { xs: 'auto', md: '50%' },
      }}
    >
      ONE TEAM ONE DREAM
    </Typography>
  );
};

export default StyledTypography;
