import React from 'react';
import { Box, Grid, Typography,Button } from '@mui/material';
import VENU_IMG from '../assets/img/hayath.jpeg';
import { styled } from '@mui/material/styles';

// Styled component for the Google Map
const GoogleMap = styled('iframe')(({ theme }) => ({
  borderRadius: 2,
  border: 0,
  width: '100%',
  height: '100%',
  borderRadius: 4,
  // position: "absolute"

}));
const MapContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  borderRadius: 4,
  minHeight: '270px',
}));

const OverlayButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: 10,
  right: 10,
  zIndex: 1,
}));
const ImageWithText = () => {
  const handleMapRedirect = () => {
    window.open("https://www.google.com/maps/place/LICC+by+Hyatt+Regency+Thrissur/@10.6201745,76.0318107,13z/data=!4m5!3m4!1s0x3ba7ee80f3f9e50b:0x6f5ed87e76b8680d!8m2!3d10.6241745!4d76.0248107", '_blank');
  };
  return (
    // <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} >
              <Grid item  xs={12} sm={6} md={6} >
              <Box 
                component="img" 
                src={VENU_IMG}
                alt="Sample Image" 
                sx={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: 2,
                  marginBottom: 2,
                }} 
              />
              <Typography variant="h6">
              Venue : LICC by Hyatt Regency , Thrissur
              </Typography>
             
              
              </Grid>

              
              <Grid sx={{position:"relative",overflow:"hidden",borderRadius: 4,minHeight:"270px"}} item xs={12} sm={6} md={6}>
            {/* /second box */}
              
              <GoogleMap
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d196043.32893273002!2d76.0318107178876!3d10.620174453194325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7ee80f3f9e50b%3A0x6f5ed87e76b8680d!2sLICC%20by%20Hyatt%20Regency%20Thrissur!5e0!3m2!1sen!2sin!4v1724535278899!5m2!1sen!2sin"
                        allowFullScreen=""
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        />
                         <OverlayButton
            variant="contained"
            color="primary"
            onClick={handleMapRedirect}
          >
            View on Google Maps
          </OverlayButton>
        
              {/* </Box> */}
              
              </Grid>
              
              {/* </Box> */}
             
    </Grid>
  );
}

export default ImageWithText;
