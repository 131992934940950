import React from 'react';
import { Box } from '@mui/material';
import { styled, keyframes } from '@mui/system';

// Define keyframes for the light and cloud animations
const createLightAnimation = () => keyframes`
  0% { opacity: 0; }
  100% { opacity: 0; }
`;

// Define keyframes for the diagonal cloud animation
const createDiagonalAnimation = () => keyframes`
   from { right: 250px; top:250px ; bottom: 0%; }
  to { right:-150%; top: -150%; bottom: 0px; }
`;

 
// Styled component for the light
// const Light = styled(Box)(({ theme }) => ({
//   width: '10px',
//   height: '10px',
//   background: 'red',
//   borderRadius: '50%',
//   bottom: '500px',
//   position: 'absolute',
//   zIndex: 10,
//   left: '20px',
//   animation: `${createLightAnimation()} 20s ease-in-out 0s infinite alternate`,
// }));

// Styled component for the cloud
const Cloud = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right:'-500px',
  top: '0px',
  left: '10px',
  opacity: 0.8,
  zIndex: 10,
  animation: `${createDiagonalAnimation()} 5s linear 0s infinite`,
}));

// Styled component for the plane image
const Img = styled('img')(({ theme }) => ({
  width: '200', // Set image width
  height: 'auto', // Maintain aspect ratio
  [theme.breakpoints.down('sm')]: {
    width: '100px', // Adjust image size for small screens
  },
  [theme.breakpoints.up('md')]: {
    width: '140px', // Adjust image size for medium and up screens
  },

 // Custom breakpoint for screens above 560px
 '@media (min-width: 560px)': {
  width: '150px', // Width for screens wider than 560px
},
}));
// React component
const CloudWithLight = () => {
  return (
    <Box position="absolute" height="400px" width="100%">
      <Cloud>
        {/* <Light /> */}
        <Img 
          src="https://images.vexels.com/media/users/3/145795/isolated/preview/05cd33059a006bf49006097af4ccba98-plane-in-flight-by-vexels.png"
          alt="Flying Plane"
        />
      </Cloud>
    </Box>
  );
};

export default CloudWithLight;
