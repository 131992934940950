const Ticker = (function TickerFactory(window) {
    'use strict';

    const Ticker = {};

    // Add a listener function to the Ticker
    Ticker.addListener = function addListener(callback) {
        if (typeof callback !== 'function') throw('Ticker.addListener() requires a function reference passed for a callback.');
        listeners.push(callback);
        if (!started) {
            started = true;
            queueFrame();
        }
    };

    let started = false;
    let lastTimestamp = 0;
    let listeners = [];

    // Request the next animation frame
    function queueFrame() {
        const requestAnimFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame;
        requestAnimFrame(frameHandler);
    }

    // Handle each frame, calling listeners with the frame time
    function frameHandler(timestamp) {
        let frameTime = timestamp - lastTimestamp;
        lastTimestamp = timestamp;
        
        if (frameTime < 0) {
            frameTime = 17;
        } else if (frameTime > 68) {
            frameTime = 68;
        }
        
        listeners.forEach(listener => listener.call(window, frameTime, frameTime / 16.6667));
        queueFrame();
    }

    return Ticker;
})(window);

export default Ticker;
