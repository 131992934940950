import React, { useEffect, useState } from 'react';
import { startFireworks } from '../assets/js/fireworks';
import "../assets/css/main.css";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import IMG from '../assets/img/dhanHO.jpeg';
// import { StyledTypography } from '../components/FireworkEffect';
import FullPageLoader from '../components/FullPageLoader';
import BouncingText from '../components/BouncingText';
import FireworkEffect from '../components/FireworkEffect';
import StyledTypography from '../components/StyledTypography';
import Container from '@mui/material/Container';
import Header_Box from '../components/HeaderBox'; 
import ImageWithText from '../components/ImageWithText'; 
import ProgramContact from '../components/ProgramContact'; 
import ContactCard from '../components/ContactCard'; 
import Schedule from "../components/Schedule";
import { scheduleData } from './EventData';
import { indigo } from '@mui/material/colors';
import CloudWithLight from '../components/CloudWithLight';
// Styled component for the Google Map
// const GoogleMap = styled('iframe')(({ theme }) => ({
//   border: 0,
//   width: '100%',
//   height: '300px',
//   [theme.breakpoints.up('md')]: {
//     width: '500px', // Adjust width for larger screens
//     height: '300px',
//   },
// }));

const EventDetailsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  color:'#a83b71',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

function EventPage() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Initialize fireworks
    // const fireworksEl = document.querySelector("#fireworks");
    // if (fireworksEl) {
    //   startFireworks(fireworksEl);
    // }

    // Simulate a data fetching delay or loading process
    const loadData = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setLoading(false); // Hide loader after data is loaded
      } catch (error) {
        console.error('Error loading data:', error);
        setLoading(false); // Hide loader even if there's an error
      }
    };
    loadData();
  }, []);

  return (
    <>
      {loading && <FullPageLoader />}
      <div className="annual-meet" >
      <Container fixed sx={{ paddingTop: '20px' }}>
      <Header_Box backgroundImage={IMG} sx={{ position: 'relative', zIndex: 1 , }}>
        <BouncingText />
      <FireworkEffect />
      <CloudWithLight/>
        <StyledTypography>
          ONE TEAM ONE DREAM
        </StyledTypography>
      </Header_Box>
        </Container>
        <main className="main-content">
          <section className="event-details">
            <h2 >Annual Conclave meet 2024</h2>
            <EventDetailsContainer>
              <Box >
                <ul>
                  <li>"This event will bring together our employees from across the country to reflect on our achievements, discuss our strategic goals, and celebrate our collective success."</li>
                 
                </ul>
                
              </Box>
            </EventDetailsContainer>
          </section>   
          <section className="event-details">
          <EventDetailsContainer>
          <ImageWithText /> 
          </EventDetailsContainer>
          </section>    
          <section className="event-details">
            <Schedule scheduleData={scheduleData} />
          </section>
          <section className="event-details">
            <h2>Transportation and Accommodation</h2>
            <ProgramContact/>
          </section>
        
          <section className="rsvp">
            <h2>Women Helpdesk</h2>
            <p>If any Support needed, feel free to reach out.</p>
            <ContactCard />
          </section>
        </main>

        <footer className="footer">
          <p>Dhanalakshmi Hire Purchase & Leasing Ltd | Non-Banking Financial Company</p>
          <p>&copy; 2024 Dhanalakshmi Hire Purchase & Leasing Ltd. All rights reserved.</p>
          <p>Powered By :IT DEPARTMENT DHANFIN</p>
        </footer>
      </div>
    </>
  );
}

export default EventPage;
