import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,Link } from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled TableContainer for responsiveness
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    margin: '0',
    padding: '0',
    width: '100%',
    '& .MuiTableCell-root': {
      padding: theme.spacing(1),
      fontSize: '0.9rem',
    },
  },
  [theme.breakpoints.up('sm')]: {
    margin: 'auto',
    padding: theme.spacing(2),
    width: '80%',
  },
}));

const ProgramContact = () => {
  const programData = [
    { Contact_Name: 'Sneha', Contact_Number: <Link href="tel:+917994429822" color="primary">7994429822</Link> },
    { Contact_Name: 'Jismi', Contact_Number: <Link href="tel:+919074711086" color="primary">9074711086</Link> },
    { Contact_Name: 'Sandra', Contact_Number: <Link href="tel:+919207065902" color="primary">9207065902</Link> },
    { Contact_Name: 'Abin', Contact_Number: <Link href="tel:+919847755630" color="primary">9847755630</Link> },
    { Contact_Name: 'Leneesh', Contact_Number: <Link href="tel:+918714134206" color="primary">8714134206</Link> },
  ]
  return (
    <StyledTableContainer component={Paper}>
      <Table aria-label="program table">
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{ fontWeight: 'bold', bgcolor: "#800020", color: 'white' }}>Contact Person</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold', bgcolor: "#800020", color: 'white' }}>Contact Number</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {programData.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="center">{row.Contact_Name}</TableCell>
              <TableCell align="center">{row.Contact_Number}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default ProgramContact;
