// src/data/eventdata.js
import profilePic1 from '../assets/img/logo.gif';
import profilePic2 from '../assets/img/logo.gif';
export const scheduleData = [
    {
      schedule: {
        time: '08:00 AM',
        place_onsite: '',
        place_online: 'Registration end on 9:00 AM',
      },
      speaker: {
        speaker_name: '',
        company: '',
        profile_pic: profilePic1,
      },
      name: 'Registration',
      id: 1
    },
    {
      schedule: {
        time: '09:00 AM',
        // place_onsite: '',
        // place_online: '(Flag Hoisted by – Syam sir and Suraj sir)',
      },
      speaker: {
        speaker_name: '',
        company: '',
        profile_pic: profilePic1,
      },
      name: 'Procession & Flag Hoisting',
      id: 2
    },
    {
        schedule: {
          time: '09:30 AM',
          // place_onsite: '',
          // place_online: 'MC will ask the dignitaries to be seated in front row sofas',
        },
        speaker: {
          speaker_name: '',
          company: '',
          profile_pic: profilePic1,
        },
        name: 'General Introduction & Welcome: Master of Ceremony',
        id: 3
      },
      {
        schedule: {
          time: '09:35 AM',
          // place_onsite: '',
          // place_online: 'Ms. Anusree',
        },
        speaker: {
          speaker_name: '',
          company: '',
          profile_pic: profilePic1,
        },
        name: 'Prayer',
        id: 4
        
      },
      {
        schedule: {
          time: '09:37 AM',
          // place_onsite: '',
          place_online: 'Dr. Sindhu.S.Pillai (Associate Vice President-Learning & Development',
        },
        speaker: {
          speaker_name: '',
          company: '',
          profile_pic: profilePic1,
        },
        name: 'Welcome Speech',
        id: 5
        
      },
      {
        schedule: {
          time: '09:42 AM',
          place_onsite: '',
          place_online: ' Mr. Anil Chandran - Patron, Dhanalakshmi group of companies',
        },
        speaker: {
          speaker_name: '',
          company: '',
          profile_pic: profilePic1,
        },
        name: "Key Note Address and Chairman's Message",
        id: 6
        
      },
      {
        schedule: {
          time: '09:52 AM',
          // place_onsite: '',
          // place_online: '',
        },
        speaker: {
          speaker_name: '',
          company: '',
          profile_pic: profilePic1,
        },
        name: 'Lighting of the Lamp',
        id: 7
        
      },
    
      {
        schedule: {
          time: '10:04 AM',
          // place_onsite: '',
          // place_online: '',
        },
        speaker: {
          speaker_name: '',
          company: '',
          profile_pic: profilePic1,
        },
        name: 'Ranga Pooja',
        id: 8
        
      },
      {
        schedule: {
          time: '10:09 AM',
          // place_onsite: '',
          // place_online: 'The rising, Product Launch, Icons Launch ',
        },
        speaker: {
          speaker_name: '',
          company: '',
          profile_pic: profilePic1,
        },
        name: 'Presentation: Mr. Suhas Soman - Director & CEO',
        id: 9
        
      },

      {
        schedule: {
          time: '10:49 AM',
          // place_onsite: '',
          // place_online: '22 no’s Award presented by Mr. ',
        },
        speaker: {
          speaker_name: '',
          company: '',
          profile_pic: profilePic1,
        },
        name: 'Dhanalakshmi Loyalty Awards',
        id: 10
        
      },
      {
        schedule: {
          time: '11:09 AM',
          // place_onsite: '',
          // place_online: '',
        },
        speaker: {
          speaker_name: '',
          company: '',
          profile_pic: profilePic1,
        },
        name: 'Entertainment',
        id: 11
        
      },
      {
        schedule: {
          time: '11:14 AM',
          // place_onsite: '',
          // place_online: '7 no’s Award presented by Mr. ',
        },
        speaker: {
          speaker_name: '',
          company: '',
          profile_pic: profilePic1,
        },
        name: 'Auto Loan Awards',
        id: 12
        
      },
      {
        schedule: {
          time: '11:24 AM',
          // place_onsite: '',
          // place_online: '1 Team Award presented by Mr. ',
        },
        speaker: {
          speaker_name: '',
          company: '',
          profile_pic: profilePic1,
        },
        name: 'Property Loan Awards',
        id: 13
        
      },
      {
        schedule: {
          time: '11:31 AM',
          // place_onsite: '',
          // place_online: 'V.P. Business Head',
        },
        speaker: {
          speaker_name: '',
          company: '',
          profile_pic: profilePic1,
        },
        name: 'Presentation: Mr. Arun Dev - V.P. Business Head',
        id: 14
        
      },
      {
        schedule: {
          time: '11:56 AM',
          place_onsite: '',
          place_online: '',
        },
        speaker: {
          speaker_name: '',
          company: '',
          profile_pic: profilePic1,
        },
        name: 'Entertainment',
        id: 15
        
      },
      {
        schedule: {
          time: '12:01 PM',
          // place_onsite: '',
          // place_online: '6 nos Award presented by Mr.',
        },
        speaker: {
          speaker_name: '',
          company: '',
          profile_pic: profilePic1,
        },
        name: 'Business Loan Awards',
        id: 16
        
      },
      {
        schedule: {
          time: '12:11 PM',
          // place_onsite: '',
          // place_online: '7 nos Award presented by Mr.',
        },
        speaker: {
          speaker_name: '',
          company: '',
          profile_pic: profilePic1,
        },
        name: 'Gold Loan Awards',
        id: 17
        
      },
      {
        schedule: {
          time: '12:23 PM',
          // place_onsite: '',
          // place_online: ' – AVP Business Head.',
        },
        speaker: {
          speaker_name: '',
          company: '',
          profile_pic: profilePic1,
        },
        name: 'Presentation: Mr. Gokul– AVP Business Head',
        id: 18
        
      },
      {
        schedule: {
          time: '12:43 PM',
          // place_onsite: '',
          // place_online: '',
        },
        speaker: {
          speaker_name: '',
          company: '',
          profile_pic: profilePic1,
        },
        name: 'Entertainment',
        id: 19
        
      },
      {
        schedule: {
          time: '12:48 PM',
          // place_onsite: '',
          // place_online: '4 nos Award presented by Mr.(Nominations – then Award presentation)',
        },
        speaker: {
          speaker_name: '',
          company: '',
          profile_pic: profilePic1,
        },
        name: 'Fund Mobilizations Awards',
        id: 20
        
      },
      {
        schedule: {
          time: '12:58 PM',
          // place_onsite: '',
          // place_online: ' Award presented by Mr.',
        },
        speaker: {
          speaker_name: '',
          company: '',
          profile_pic: profilePic1,
        },
        name: 'Special Award',
        id: 21
        
      },
      {
        schedule: {
          time: '01:10 PM',
          // place_onsite: '',
          // place_online: '',
        },
        speaker: {
          speaker_name: '',
          company: '',
          profile_pic: profilePic1,
        },
        name: 'Lunch Break',
        id: 22
        
      },
      {
        schedule: {
          time: '02:30 - 04:00 PM ',
            // place_onsite: '',
            // place_online: '',
        },
        speaker: {
          speaker_name: '',
          company: '',
          profile_pic: profilePic1,
        },
        name: 'Entertainment',
        id: 23
        
      },
      {
        schedule: {
          time: '04:00 - 06:00 PM ',
            // place_onsite: '',
            // place_online: '',
        },
        speaker: {
          speaker_name: '',
          company: '',
          profile_pic: profilePic1,
        },
        name: 'DJ Party',
        id: 24
        
      },

    // Add more events as needed
  ];
  