
import './App.css';
import EventPage from './pages/EventPage';

function App() {
  return (
 <EventPage></EventPage>
    
  );
}

export default App;
