import React from 'react';
import '../assets/css/DecorativeLamp.css';


const DecorativeLamp = () => {
  return (
    <div className="lamp">
      <div className="top"></div>
      <div className="flame"></div>
    </div>
  );
};

export default DecorativeLamp;
